/**
 * Permission
 */
export default {
    pm: {
        Users: '用户管理',
        Users_Users: '用户管理',
        Users_Usernames: '用户名管理',
        Users_Usernames_Add: '添加',
        Users_Usernames_Import: '导入',
        Users_Usernames_Delete: '删除',
        'Users_Usernames_Bulk-Delete': '批量删除',
        Users_Users_Detail: '详情',
        Users_Users_Export: '导出',
        Content: '内容管理',
        Content_Posts: '帖子管理',
        Ambassadors: '大使计划',
        Ambassadors_Applications: '大使申请',
        'Ambassadors_Applications_Public-Pool': '申请公海池',
        'Ambassadors_Applications_Private-Pool': '申请私海池',
        'Ambassadors_Applications_Review-Records': '审核记录',
        Ambassadors_Ambassadors: '大使管理',
        Ambassadors_Ambassadors_Ambassadors: '大使',
        'Ambassadors_Ambassadors_Ambassadors_All-Data': '所有数据',
        'Ambassadors_Ambassadors_Ambassadors_Personal-Data': '个人数据',
        Ambassadors_Data: '数据管理',
        'Ambassadors_Data_Ambassador-Data': '大使任务数据',
        'Ambassadors_Data_Ambassador-Data_Export': '导出',
        Ambassadors_Configurations: '配置管理',
        'Ambassadors_Configurations_Private-Pool-Config': '申请私海池配置',
        'Ambassadors_Configurations_Private-Pool-Config_Add': '添加',
        'Ambassadors_Configurations_Private-Pool-Config_Edit': '编辑',
        'Ambassadors_Configurations_Private-Pool-Config_Delete': '删除',
        Incentives: '激励计划',
        'Incentives_Incentive-Seasons': '激励计划活动列表',
        'Incentives_Incentive-Seasons_Add': '创建',
        'Incentives_Incentive-Seasons_Edit': '编辑',
        'Incentives_Incentive-Seasons_Delete': '删除',
        'Incentives_Task-Config_Add': '创建',
        'Incentives_Task-Config_Edit': '编辑',
        'Incentives_Task-Config_Delete': '删除',
        'Incentives_Task-Config_Sort': '排序',
        Creator_Center: '创作者中心',
        'Creator_Center-Topic': '话题管理',
        'Creator_Center-Creator_Hub': '创作学院',
        'Creator_Center-Top_Posts': '推荐帖子',
        Reports: '举报管理',
        'Reports_Report-Moderation': '举报审核',
        'Reports_Report-Moderation_Posts': '帖子举报',
        'Reports_Report-Moderation_Replies': '评论举报',
        'Reports_Report-Moderation_Profiles': '个人资料举报',
        'Reports_Moderation-Records': '审核记录',
        'Reports_Moderation-Records_Post-Moderation-Records': '帖子举报审核记录',
        'Reports_Moderation-Records_Reply-Moderation-Records': '评论举报审核记录',
        'Reports_Moderation-Records_Profile-Moderation-Records': '个人资料举报审核记录',
        'Client-Config': '客户端配置',
        'Client-Config_Plaza-Config': '数字文明广场',
        'Client-Config_Super-Users': '超级账号',
        'Client-Config_Super-Users_Add': '添加',
        'Client-Config_Super-Users_Edit': '编辑',
        'Client-Config_Super-Users_Delete': '删除',
        'Client-Config_App-Version': 'App版本管理',
        Marketplace: '交易市场',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT合集管理',
        Marketplace_NFT_NFT: 'NFT管理',
        'Marketplace_NFT_NFT-Marketplace': 'NFT交易市场',
        Marketplace_Token: '代币',
        'Marketplace_Token_Token-Config': '代币配置',
        'Marketplace_Token_Token-Config_Edit': '编辑',
        'Marketplace_Token_Token-Config_Sort': '排序',
        Airdrop: '空投管理',
        'Airdrop_Airdrop-Seasons': '官方空投活动列表',
        'Airdrop_Airdrop-Seasons_Create': '创建',
        'Airdrop_Airdrop-Seasons_Edit': '编辑',
        'Airdrop_Airdrop-Seasons_Delete': '删除',
        'Airdrop_Airdrop-Seasons_Distribute': '发放',
        'Airdrop_Airdrop-Config': '官方空投参数配置',
        'Airdrop_Airdrop-Config_Edit': '编辑',
        Marketplace_Token_Pool: '流动性池',
        Marketplace_Token_Pool_Create: '添加',
        Marketplace_Token_Pool_Edit: '编辑',
        'System-Management': '系统管理',
        'System-Management_Accounts': '账号管理',
        'System-Management_Accounts_Create': '创建',
        'System-Management_Accounts_Edit': '编辑',
        'System-Management_Accounts_Delete': '删除',
        'System-Management_Roles': '角色管理',
        'System-Management_Roles_Create': '创建',
        'System-Management_Roles_Edit': '编辑',
        'System-Management_Roles_Delete': '删除',
        'System-Management_Permissions': '权限管理',
        'System-Management_Logs': '系统日志',
        'System-Management_Exports': '下载管理',
        'System-Management_Exports_Exports': '导出',
        'System-Management_Exports_Exports_All-Data': '所有数据',
        'System-Management_Exports_Exports_Personal-Data': '个人数据',
    },
};
