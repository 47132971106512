/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: 'Home',

        /**
         * Reports
         *
         * 举报管理
         */
        Reports: 'Reports',

        'Reports_Report-Moderation': 'Report Moderation',

        'Reports_Moderation-Records': 'Moderation Records',

        'report-management': '举报管理',
        rm: {
            'report-review': 'Report Review',
            'post-report': 'Posts',
            'comment-report': 'Replies',
            'account-report': 'Profile',
            'report-review-records': 'Report Review Records',
            posts: 'Posts',
            replies: 'Replies',
            profile: 'Profile',
        },

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': 'System Management',

        'System-Management_Accounts': 'Accounts',

        'System-Management_Roles': 'Roles',

        'System-Management_Logs': 'Logs',

        'System-Management_Exports': 'Downloads',
    },
};
