/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: '首页',

        /**
         * Reports
         *
         * 举报管理
         */
        Reports: '举报管理',

        'Reports_Report-Moderation': '举报审核',

        'Reports_Moderation-Records': '审核记录',

        'report-management': '举报管理',
        rm: {
            'report-review': '举报审核',
            'post-report': '帖子举报',
            'comment-report': '评论举报',
            'account-report': '个人资料举报',
            'report-review-records': '举报审核记录',
            posts: '帖子举报审核记录',
            replies: '评论举报审核记录',
            profile: '个人资料举报审核记录',
        },

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': '系统管理',

        'System-Management_Accounts': '账号管理',

        'System-Management_Roles': '角色管理',

        'System-Management_Logs': '系统日志',

        'System-Management_Exports': '下载管理',
    },
};
