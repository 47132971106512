export default {
    su: {
        t: {
            c: {
                airdropCountRange: 'Airdrop Recipient Range',
            },
        },
        f: {
            pleaseInputUID: 'Please entry UID',
            uidFormatError: '请输入正确的uid',
            AccountExist: 'Account already exists',
            airdropCountRangeError: 'a positive integer between 1 and 1000000',
            AccountNotExist: 'Account not found',
            MaxAirdropLtMin: '最大空头人数要大于最小空投人数',
            uidRepeat: 'UID already exists',
        },
    },
};
