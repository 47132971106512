export default {
    p: {
        uu: {
            h: {
                status: '状态',
                baseInfo: '基本信息',
            },
            columns: {
                uid: 'UID',
                userName: '用户名',
                nickname: '昵称',
                bio: '个人简介',
                parentUid: '上级UID',
                registrationTime: '注册时间',
            },
            status: {
                account: {
                    normal: '正常',
                    disabled: '禁用',
                },
                muted: {
                    normal: '正常',
                    muted: '禁言',
                },
            },
        },
    },
};
