export default {
    p: {
        uu: {
            h: {
                status: '状态',
                baseInfo: '基本信息',
            },
            msg: {
                exportTips: 'Exporting, please check "System Management-Download Management" later',
            },
            columns: {
                uid: 'UID',
                userName: 'Username',
                nickname: 'Nickname',
                bio: 'Bio',
                parentUid: 'Parent UID',
                registrationTime: 'Registration Time',
            },
            status: {
                account: {
                    normal: 'Normal',
                    disabled: 'Disabled',
                },
                muted: {
                    normal: 'Normal',
                    muted: 'Muted',
                },
            },
        },
    },
};
