export default {
    /**
     * Button
     */
    b: {
        add: 'Add',
        approve: 'Approve',
        assign: 'Assign',
        bulkDelete: 'Bulk Delete',
        cancel: 'Cancel',
        confirm: 'Confirm',
        create: 'Create',
        delete: 'Delete',
        details: 'Details',
        edit: 'Edit',
        export: 'Export',
        import: 'Import',
        download: 'Download',
        login: 'Login',
        logout: 'Logout',
        reject: 'Reject',
        remark: 'Remark',
        reset: 'Reset',
        save: 'Save',
        search: 'Search',
        transfer: 'Transfer',
    },
    /**
     * Common
     */
    c: {
        areyousure: 'Are you sure?',
        areyousureBulk: 'Are you sure you want to perform this bulk action?',
        checkGroupId: 'Group ID verification failed',
        checkPostId: 'Post ID verification failed',
        checkUserId: 'User ID verification failed',
        developing: 'Developing',
        welcome: 'Welcome!',
        pleaseInput: 'Please input {key}',
        password: 'Password',
        operationCompleted: 'Operation Completed',
        importSuccess: 'Imported {total} records successfully',
        importError: 'Failed to import records because some data do not meet the format requirements',
        importPartSuccess: 'Imported {total} records successfully. {failCount} records failed to import.',
        notFound: '{value} not found',
        alreadyExists: '{value} already exists',
        /**
         * Change Password Modal
         */
        cpm: {
            changePassword: 'Change password',
            oldPassword: 'Current password',
            newPassword: 'New password',
            sureNewPassword: 'Confirm password',
            inputPasswordNotMatch: '新密码与确认密码不一致',
        },
    },
    /**
     * Table
     */
    t: {
        /**
         * Columns
         */
        c: {
            account: 'Account',
            action: 'Action',
            age: 'Age',
            appliedAt: 'Applied At',
            avatar: 'Avatar',
            bio: 'Bio',
            city: 'City',
            country: 'Country',
            countryRegion: 'Country/Region',
            createdAt: 'Created At',
            createdBy: 'Created By',
            date: 'Date',
            dateRange: 'Date Range',
            email: 'Email',
            fullName: 'Name',
            gender: 'Gender',
            link: 'Link',
            nickname: 'Nickname',
            operatedAt: 'Operated At',
            detail: 'Detail',
            operatedBy: 'Operated By',
            operationsUid: 'Mkt UID',
            owner: 'Owner',
            occupation: 'Occupation',
            role: 'Role',
            sort: 'Sort',
            status: 'Status',
            type: 'Type',
            updatedAt: 'Updated At',
            url: 'URL',
            user: 'User',
            username: 'Username',
            video: 'Video',
        },
    },
    /**
     * Component
     */
    component: {
        /**
         * Change password Modal
         */
        cpm: {},
    },
    /**
     * Message
     */
    m: {
        exportTips: `Exporting. Please check 'System Management - Downloads' later`,
    },
};
