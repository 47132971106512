import { AreaChartOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
/* 首页 Home */
import PageDashboard from '@/pages/dashboard';

/* 举报管理 Reports */
// 举报审核
import PageReportReviewPosts from '@/pages/report-management/report-review/posts';
import PageReportReviewReplies from '@/pages/report-management/report-review/replies';
import PageReportReviewProfile from '@/pages/report-management/report-review/profile';
// 举报审核记录
import PageReportReviewRecordsPosts from '@/pages/report-management/report-review-records/posts';
import PageReportReviewRecordsReplies from '@/pages/report-management/report-review-records/replies';
import PageReportReviewRecordsProfile from '@/pages/report-management/report-review-records/profile';

/* 系统管理 System Management */
// 账号管理
import PageAccountManagement from '@/pages/system-management/account-management';
// 角色管理
import PageRoleManagement from '@/pages/system-management/role-management';
// 系统日志
import PageLogs from '@/pages/system-management/logs';
// 导出管理
import PageExports from '@/pages/system-management/exports';

/**
 * Types
 */
import type { MenuDataItem } from '@ant-design/pro-components';

const routes = [
    /* 首页 Home */
    {
        element: <PageDashboard />,
        icon: <HomeOutlined />,
        name: (<FormattedMessage id="m.Home" />) as unknown as string,
        path: '/',
    },
    /* 举报管理 Reports */
    {
        children: [
            {
                children: [
                    {
                        access: 'Reports_Report-Moderation_Posts',
                        element: <PageReportReviewPosts />,
                        name: <FormattedMessage id="m.rm.post-report" />,
                        path: 'posts',
                    },
                    {
                        access: 'Reports_Report-Moderation_Replies',
                        element: <PageReportReviewReplies />,
                        name: <FormattedMessage id="m.rm.comment-report" />,
                        path: 'replies',
                    },
                    {
                        access: 'Reports_Report-Moderation_Profiles',
                        element: <PageReportReviewProfile />,
                        name: <FormattedMessage id="m.rm.account-report" />,
                        path: 'profile',
                    },
                ],
                name: <FormattedMessage id="m.Reports_Report-Moderation" />,
                access: 'Reports_Report-Moderation',
                path: 'moderation',
            },
            {
                children: [
                    {
                        access: 'Reports_Moderation-Records_Post-Moderation-Records',
                        element: <PageReportReviewRecordsPosts />,
                        name: <FormattedMessage id="m.rm.posts" />,
                        path: 'posts',
                    },
                    {
                        access: 'Reports_Moderation-Records_Reply-Moderation-Records',
                        element: <PageReportReviewRecordsReplies />,
                        name: <FormattedMessage id="m.rm.replies" />,
                        path: 'replies',
                    },
                    {
                        access: 'Reports_Moderation-Records_Profile-Moderation-Records',
                        element: <PageReportReviewRecordsProfile />,
                        name: <FormattedMessage id="m.rm.profile" />,
                        path: 'profile',
                    },
                ],
                name: <FormattedMessage id="m.Reports_Moderation-Records" />,
                access: 'Reports_Moderation-Records',
                path: 'moderation-records',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Reports" />,
        access: 'Reports',
        path: 'reports',
    },
    /* 系统管理 System Management */
    {
        children: [
            {
                access: 'System-Management_Accounts',
                element: <PageAccountManagement />,
                name: <FormattedMessage id="m.System-Management_Accounts" />,
                path: 'accounts',
            },
            {
                access: 'System-Management_Roles',
                element: <PageRoleManagement />,
                name: <FormattedMessage id="m.System-Management_Roles" />,
                path: 'roles',
            },
            {
                access: 'System-Management_Logs',
                element: <PageLogs />,
                name: <FormattedMessage id="m.System-Management_Logs" />,
                path: 'logs',
            },
            {
                access: 'System-Management_Downloads',
                element: <PageExports />,
                name: <FormattedMessage id="m.System-Management_Exports" />,
                path: 'exports',
            },
        ],
        icon: <SettingOutlined />,
        name: <FormattedMessage id="m.System-Management" />,
        access: 'System-Management',
        path: 'system-management',
    },
] as MenuDataItem[];

export default routes;
